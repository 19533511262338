import 'cross-fetch/polyfill';

export class HttpUtils {
    public static loadScript(url, token?: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script: any = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            if (token) {
                script['data-ad-client'] = token;
            }
            if (script.readyState) {
                // IE
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        resolve();
                    }
                };
            } else {
                // Others
                script.onload = resolve;
                script.onerror = reject;
            }
            script.src = url;
            document.head.appendChild(script);
        });
    }

    public static async fetch(url, options?, jsonResponse = true): Promise<any> {
        options = options || {};
        options.headers = options.headers || new Headers();
        // options.headers.set('Content-type', 'application/json');

        const response = await fetch(url, options);
        HttpUtils.assertFetchStatus(response);
        return jsonResponse ? response.json() : response.text();
    }

    private static assertFetchStatus(res): void {
        if (!(res.status >= 200 && res.status < 300)) {
            throw new Error(`Request failed: ${res.status} - ${res.statusText}`);
        }
    }
}
