import React, { useContext, useEffect, useState } from 'react';

import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './SocialButtons.css';
import { DEFAULT_AVATAR, DEFAULT_AVATAR_BACKGROUND, getAvatarAnalyticsKey } from '../../constants/AvatarImagesData';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { RegistrationSource } from '../../models/User/RegistrationSource';
import { RECAPTCHA_ACTIONS, RECAPTCHA_ACTIONS_TYPE, RECAPTCHA_MODES } from '../../molecules/Captcha/hooks/useCaptcha';
import AdFreeService from '../../services/AdFreeService';
import adFreeService from '../../services/AdFreeService';
import { Analytics } from '../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../services/AppInsight';
import { AuthDataContext } from '../../services/AuthDataReact';
import { CookieService } from '../../services/CookieService';
import { LocalStorageService } from '../../services/LocalStorage';
import PaymentService from '../../services/PaymentService';
import UserService, { AuthType } from '../../services/UserService';
import { setAuthValues, TAuthValues } from '../../store/ducks/authData/authData';
import { setSideMenuActivePage, setSignInUpState } from '../../store/ducks/layout';
import { activePageSelector } from '../../store/ducks/layoutSelectors';
import { setPreLoadData } from '../../store/ducks/preLoadData';
import { setShowRecaptcha } from '../../store/ducks/recaptcha';
import { setActiveUserSubscriptions } from '../../store/ducks/subscription/common';


type SocialButtonsProps = {
  t?: any;
  className?: string;
  signIn?: boolean;
  game?: string | IGame;
  category?: string;
  checkBox?: boolean;
  errorMessageSocial?: (v: number) => void;
  email?: string;
  password?: string;
  provider?: string;
  authType?: AuthType | string;
  authValues?: TAuthValues;
  captchaToken?: string;
  showChallengeRecaptcha?: boolean;
  clearCaptchaData?: () => void;
  getCaptchaToken?: (arg: RECAPTCHA_ACTIONS_TYPE) => void;
};

export const SocialButtons = React.memo((
  {
    t,
    className,
    signIn,
    game,
    category,
    checkBox,
    errorMessageSocial,
    email,
    password,
    provider,
    authType,
    authValues,
    showChallengeRecaptcha,
    clearCaptchaData,
    getCaptchaToken,
    captchaToken,
    ...rest
  }: SocialButtonsProps
) => {
  const dispatch = useDispatch();
  const authDataContext = useContext(AuthDataContext);
  const [facebook, setFacebook] = useState<JSX.Element>(<></>);
  const [google, setGoogle] = useState<JSX.Element>(<></>);
  const [facebookToken, setFacebookToken] = useState<AuthType>();
  const [googleToken, setGoogleToken] = useState<AuthType>();
  const user = useSelector(({ user }) => user);
  const activePage = useSelector(activePageSelector);
  const socialRegistrationLocation = useSelector(({ socialRegistrationLocation }) => socialRegistrationLocation);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  useEffect(() => {
    void getFacebookButton();
    void getGoogleButton();
  }, []);

  useEffect(() => {
    if (captchaToken) {
      if (googleToken) {
        void fetchLoginViaGoogle(googleToken);
      } else if (facebookToken) {
        void fetchLoginViaFacebook(facebookToken);
      }
    }
  }, [captchaToken]);

  const fetchLoginViaFacebook = async (token: string) => {
    try {
      const result = await UserService.loginViaFacebook({
        token,
        email,
        emailRetrievalConsent: checkBox,
        registrationPlaceUrl: new URL(UserService.generateUrl()),
        password,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });

      handleSocialLoginSuccess(AuthType.Facebook, token, result);
    } catch (err) {
      handleSocialLoginError(AuthType.Facebook, token, err);
    }
  };
  const fetchLoginViaGoogle = async (token: string) => {
    try {
      const result = await UserService.loginViaGoogle({
        token,
        email,
        emailRetrievalConsent: checkBox,
        registrationPlaceUrl: new URL(UserService.generateUrl()),
        password,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });

      handleSocialLoginSuccess(AuthType.Google, token, result);
    } catch (err) {
      handleSocialLoginError(AuthType.Google, token, err);
    }
  };
  const handleSocialLoginSuccess = (authProvider: AuthType, authProviderToken: string, isRegistered: boolean) => {
    const provider = authProvider === AuthType.Google ? AuthType.Google : AuthType.Facebook;

    clearCaptchaData();
    authDataContext.setAuthData({
      ...authDataContext.value,
      authProvider: provider,
      authProviderToken
    });
    LocalStorageService.setItem('authProvider', provider);
    afterSuccessLogin({ ...rest, provider }, isRegistered === true);
  };
  const handleSocialLoginError = (
    authProvider: AuthType,
    authProviderToken: string,
    error: { errCode: number, errEmail: string }
  ) => {
    const provider = authProvider === AuthType.Google ? AuthType.Google : AuthType.Facebook;

    if (error.errCode !== 1023) {
      clearCaptchaData();
    }

    if (error.errCode === 1007) {
      authDataContext.setAuthData({
        ...authDataContext.value,
        authProvider: provider,
        authProviderToken
      });
      UserService.openRequestEmail();
    }

    if (error.errCode === 1018) {
      authDataContext.setAuthData({
        ...authDataContext.value,
        email: error.errEmail,
        authProvider: provider
      });
      UserService.openAfterSignPanel();
    } else {
      dispatch(setShowRecaptcha(error.errCode === 1023));
      errorMessageSocial(error.errCode);
    }
  };
  const onMessage = (event: MessageEvent) => {
    if (event.origin !== 'https://login.arkadium.com') {
      return;
    }

    if (event.data?.type === 'ark-fb-action' && event.data?.action === 'button-click') {
      void Analytics.trackEvent(
        Analytics.profile.loginClick(category, game as IGame, RegistrationSource.FACEBOOK)
      );
    }

    if (event.data?.type === 'ark-google-action' && event.data?.action === 'button-click') {
      void Analytics.trackEvent(
        Analytics.profile.loginClick(category, game as IGame, RegistrationSource.GOOGLE)
      );
    }

    if (event.data?.type === 'ark-fb-action' && event.data.data?.access_token) {
      dispatch(setAuthValues({ ...authValues, authType: AuthType.Facebook }));
      setFacebookToken(event.data.data.access_token);
      getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
    }

    if (event.data?.action === 'auth-google-user' && event.data.user?.access_token) {
      dispatch(setAuthValues({ ...authValues, authType: AuthType.Google }));
      setGoogleToken(event.data.user.access_token);
      getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
    }
  };

  function afterSuccessLogin(props: Partial<SocialButtonsProps>, isRegistered: boolean) {
    const { game, category, provider } = props;
    let loginRegistrationType: RegistrationSource;

    switch (provider) {
      case 'Facebook': {
        loginRegistrationType = RegistrationSource.FACEBOOK;
        break;
      }

      case 'Google': {
        loginRegistrationType = RegistrationSource.GOOGLE;
        break;
      }
    }

    LocalStorageService.setItem('authProvider', provider);

    if (isRegistered) {
      // first time registered from social (true equals FIRST TIME)
      UserService.userLoad().then(() => {
        const storedUser = UserService.getUserFromStore();

        batch(() => {
          dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));

          // These additional check fixes bug #142914, when after logout from Google/facebook
          // when user falls into PERSONALIZE_PROFILE after logout and can't go to login page
          // until page reload
          if (storedUser && storedUser.name && !storedUser.avatar) {
            dispatch(setSignInUpState(SignInUpComponents.PERSONALIZE_PROFILE));
          }
        });
        let userAvatar = DEFAULT_AVATAR.filename;
        let userBackground = DEFAULT_AVATAR_BACKGROUND;

        if (user) {
          userAvatar = user.avatar;
          userBackground = user.avatarBackground;
        }

        const avatarAnalyticsKey = getAvatarAnalyticsKey(userAvatar);

        LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
        // To have LP userAttribute {registered: true} set here we don't have email confirmed,
        // final Eagle user id, and LP session started with this id to set it in LP to correct user
        void Analytics.trackEvent(
          Analytics.profile.registration(
            category,
            game,
            loginRegistrationType,
            avatarAnalyticsKey,
            userBackground,
            socialRegistrationLocation
          )
        );
      });
    } else {
      void Analytics.trackEvent(
        Analytics.profile.loginSuccess(
          props.category,
          props.game,
          socialRegistrationLocation,
          loginRegistrationType
        )
      );
      LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.LOGIN_BTN, { action: 'login' });
      UserService.userLoad().then(() => {
        AdFreeService.vignetteAdsFix();

        if (activePage !== HeaderSideMenuTabs.SHOP_TAB) {
          UserService.closePanel();
        }

        UserService.resetPurchaseFlow();
      });
    }

    void fetchGetSubscriptions();
  }

  const fetchGetSubscriptions = async () => {
    try {
      const result = await PaymentService.getSubscriptions();

      handleFetchGetSubscriptionsSuccess(result);
    } catch (err) {
      handleFetchGetSubscriptionsError(err);
    }
  };
  const handleFetchGetSubscriptionsSuccess = (subscriptions: UserSubscription[]) => {
    if (subscriptions.length > 0) {
      dispatch(setActiveUserSubscriptions(subscriptions));
      //Check subscription

      const isUserSubscriber = UserService.isUserSubscriber();
      const isUserBonusOnly = UserService.isUserHasBonusOnly();

      CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, isUserBonusOnly);
      CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, isUserSubscriber);

      if (isUserSubscriber) {
        CookieService.setArkCookie(
          LS_COOKIE_CONSTS.SUBSCRIPTION_ID,
          UserService.getSubscriptionId(),
          30
        );
      }

      dispatch(
        setPreLoadData({
          adFree: adFreeService.adFreeTurnOffFilter(),
          subscription: isUserSubscriber
        })
      );
    }
  };
  const handleFetchGetSubscriptionsError = (err: number) => {
    AppInsightService.trackAppError(err, { data: 'userLogin()' });
  };

  async function getFacebookButton() {
    try {
      let src = await UserService.getFacebookButton();

      setFacebook(
        <iframe
          className={styles.facebookButton}
          title="facebook"
          src={src}
          id="facebook"
          height="50px"
          width="220px"
        />
      );
    } catch (e) {
      console.error('Error on getting social button');
    }
  }

  async function getGoogleButton() {
    try {
      let src = await UserService.getGoogleButton();

      setGoogle(
        <iframe
          className={styles.googleButton}
          title="google"
          src={src}
          id="google"
          height="50px"
          width="220px"
        />
      );
    } catch (e) {
      console.error('Error on getting social button');
    }
  }

  const renderSocialButtons = (provider?: string) => {
    switch (provider) {
      case 'GOOGLE':
        return <>{google}</>;
      case 'FACEBOOK':
        return <>{facebook}</>;
      default:
        return (
          <>
            {facebook}
            {google}
          </>
        );
    }
  };

  return <div className={className}>{renderSocialButtons(provider)}</div>;
});
