import './polyfills';
import React, { Suspense } from 'react';

import { loadableReady } from '@loadable/component';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import Cookies from 'js-cookie';
import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { withSSR } from 'react-i18next';
import { Provider } from 'react-redux';

import './App.css';
import { AppBodyWrapper } from './client/appbody/AppBodyWrapper';
import { RouterWrapper } from './client/atoms/RouterWrapper/RouterWrapper';
import { environment } from './client/config/environment';
import { Snackbar } from './client/FigmaStyleguide/Snackbar/Snackbar';
import { LS_COOKIE_CONSTS } from './client/models/Enums';
import { ABTEST_COOKIE, ABTestManager } from './client/services/ABTests/ABTestManager';
import { ABTestProvider } from './client/services/ABTests/ABTestReact';
import { GDPRMediatorService } from './client/services/Analytics/GDPR';
import { LeanplumAnalytics } from './client/services/Analytics/LeanplumAnalytics';
import { AppInsightService } from './client/services/AppInsight';
import { AuthData, AuthDataProvider } from './client/services/AuthDataReact';
import { DisplayAdService } from './client/services/DisplayAdService';
import { GameService } from './client/services/GameService';
import { GoogleAnalyticsService } from './client/services/GoogleAnalyticsService';
import { LocalStorageService } from './client/services/LocalStorage';
import { MediaContextProvider } from './client/services/MediaService';
import ScrollToTopService from './client/services/ScrollToTopService';
import { SearchService } from './client/services/Search';
import { StartupService } from './client/services/StartupService';
import { UrlService } from './client/services/UrlService';
import { reduxStore } from './client/store';
import { i18nClientInit } from './i18n';
import { MiscUtils } from './utils/MiscUtils';

const MsalContainer = React.lazy(() => import('./client/appbody/MsalContainer/MsalContainer'));
const MsalProvider = React.lazy<any>(() =>
    import('@azure/msal-react').then((module) => {
        return { default: module.MsalProvider };
    })
);
// eslint-disable-next-line import/order
import { EventType } from '@azure/msal-browser';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: false; // removes the `sm` breakpoint
        md: true;
        lg: false; // removes the `lg` breakpoint
        xl: true;
    }
}

const { store, history } = reduxStore;

(window as any).STORE = store;
LocalStorageService.setItem(LS_COOKIE_CONSTS.ADO_BUILD_ID, environment.ADO_BUILD_ID);

i18nClientInit();

const { texts, currentLang } = store.getState();
UrlService.currentLang = currentLang;

(Provider as any).displayName = 'Provider';

SearchService.initFuse(GameService.gameModelToGame(store.getState().games));

export class App extends React.Component {
    static displayName = 'App';
    protected abtests: ABTestManager;
    protected theme;

    state = {
        authData: {
            email: '',
        } as AuthData,
        MSALloading: true,
        MSALconfig: null,
        MSALinstance: null,
        callbackId: null,
    };

    constructor(props) {
        super(props);
        const state = store.getState();
        const cookie = Cookies.get(ABTEST_COOKIE);
        this.abtests = new ABTestManager();
        this.abtests.init(state.abTests, cookie && cookie);

        this.theme = createTheme({
            components: {
                MuiGrid: {
                    styleOverrides: {
                        // every grid container will have these styles
                        container: {
                            padding: '0 1rem',
                        },
                    },
                },
            },
            breakpoints: {
                values: {
                    xs: 0,
                    md: 600,
                    xl: 1025, // todo check 1025
                },
            },
            typography: {
                fontFamily: 'inherit',
            },
        });
    }

    initLibs = () => {
        // restore lock viewport scroll ability
        document.body.style.overflow = 'initial';
        document.body.style.position = 'initial';
        // for games team set global variable
        (window as any).arenaName = environment.ARENA_DOMAIN;
        GDPRMediatorService.init();
        StartupService.init();
        DisplayAdService.start();

        const consent = GDPRMediatorService.consentBy3rdPartyProvider();

        if (consent.GOOGLE) {
            GoogleAnalyticsService.init();
        }

        if (consent.APP_INSIGHTS) {
            AppInsightService.init();
        }

        LeanplumAnalytics.init();
    };

    setMsalEvent = async () => {
        const { msalConfig } = await import(/* webpackChunkName: "msalAuthConfig" */ './helpers/msalAuth/authConfig');
        this.state.MSALconfig = msalConfig;
        /**
         * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
         * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
         */
        const { PublicClientApplication } = await import(
            /* webpackChunkName: "@azure/msal-browser" */ '@azure/msal-browser'
        );
        const msalInstance = new PublicClientApplication(msalConfig);
        this.state.MSALinstance = msalInstance;
        this.setState({ MSALloading: false });

        // This will be run on component mount
        const callbackId = msalInstance.addEventCallback(async (message) => {
            // This will be run every time an event is emitted after registering this callback
            const isAuth = (await msalInstance.getAllAccounts().length) > 0;
            if (
                message.eventType === EventType.LOGIN_SUCCESS ||
                (isAuth && message.eventType === EventType.HANDLE_REDIRECT_END)
            ) {
                // Do something with the result
                setTimeout(() => {
                    this.initLibs();
                }, 1500);
            }
        });

        this.setState({ callbackId: callbackId });
    };

    componentDidMount() {
        //check msal usage on env
        if (!MiscUtils.IsEnvUsingMsal(environment.Name)) {
            this.initLibs();
        } else {
            //env using msal
            this.setMsalEvent();
        }
    }

    componentWillUnmount() {
        // This will be run on component unmount
        if (this.state.callbackId) {
            this.state.MSALinstance.removeEventCallback(this.state.callbackId);
        }
    }

    setAuthData = (authData) => {
        this.setState((prevState) => ({ ...prevState, authData }));
    };

    render() {
        const toRender = (
            <ABTestProvider manager={this.abtests}>
                <HelmetProvider>
                    <AuthDataProvider value={this.state.authData} setAuthData={this.setAuthData}>
                        <Provider store={store}>
                            <ConnectedRouter history={history}>
                                <RouterWrapper>
                                    <MediaContextProvider>
                                        <ThemeProvider theme={this.theme}>
                                            <AppBodyWrapper />
                                            <ScrollToTopService />
                                            <Snackbar />
                                        </ThemeProvider>
                                    </MediaContextProvider>
                                </RouterWrapper>
                            </ConnectedRouter>
                        </Provider>
                    </AuthDataProvider>
                </HelmetProvider>
            </ABTestProvider>
        );
        if (!MiscUtils.IsEnvUsingMsal(environment.Name)) {
            return toRender;
        }
        if (this.state.MSALloading) {
            return '';
        }
        return (
            <Suspense fallback={'Loading auth'}>
                <MsalProvider instance={this.state.MSALinstance}>
                    <MsalContainer>{toRender}</MsalContainer>
                </MsalProvider>
            </Suspense>
        );
    }
}

const ExtendedApp = withSSR()(App);
const root = document.getElementById('root');

const renderMethod = root && root.innerHTML !== '' ? hydrate : render;
loadableReady(() => {
    renderMethod(
        <ExtendedApp initialLanguage={currentLang} initialI18nStore={{ [currentLang]: { translation: texts } }} />,
        root
    );
});
export default App;
