import React from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { batch, connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';


import styles from './AppBody.css';
import { langingRoutes, routes } from '../../routes';
import { MiscUtils } from '../../utils/MiscUtils';
import { StructuredDataOrganization } from '../atoms/StructuredData';
import { TagManager } from '../atoms/TagManager/TagManager';
import { TextField, TextFieldTypes } from '../atoms/TextField/TextField';
import { HeaderSideMenuTabs } from '../constants/HeaderSideMenuTabs';
import { PageTypes, ProfileRightSideRoutes } from '../constants/Pages';
import { FavoritesHandlerFuncComponent } from '../features/Favorites/FavoritesHandlerFuncComponent';
import { Footer } from '../FigmaStyleguide/Footer/Footer';
import { LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../models/Enums';
import { SubscriptionSource } from '../models/Subscription/SubscriptionData';
import HeaderSideMenu from '../organisms/HeaderSideMenu/HeaderSideMenu';
import { LeaderboardNotificationType } from '../organisms/Leaderboard/Leaderboard';
import { MobileNavMenuSplitter } from '../organisms/MobileNavigationUxRedesigned/MobileNavigationUxRedesigned';
import { useABTest } from '../services/ABTests/ABTestReact';
import AdFreeService from '../services/AdFreeService';
import adFreeService from '../services/AdFreeService';
import { Analytics } from '../services/Analytics/Analytics';
import { AppInsightService } from '../services/AppInsight';
import { CookieService } from '../services/CookieService';
import { DisplayAdService } from '../services/DisplayAdService';
import { lsDispatchGamePurchaseRequestFail } from '../services/LocalStorageListenerLogic';
import { Media } from '../services/MediaService';
import PaymentService from '../services/PaymentService';
import { PWAService } from '../services/PWAService';
import { UrlService } from '../services/UrlService';
import UserService, { EmailCodes } from '../services/UserService';
import {
  setProfilePanelState,
  setSideMenuActivePage,
  setSideMenuOpened,
  setSideMenuRequest,
  setSnackbarData
} from '../store/ducks/layout';
import { activePageSelector } from '../store/ducks/layoutSelectors';
import { setPreLoadData } from '../store/ducks/preLoadData';
import { setActiveUserSubscriptions } from '../store/ducks/subscription/common';

const RightSlideInPanelBase = loadable(() =>
  MiscUtils.loadableRetry(() => import('../organisms/RightSlideInPanel/RightSlideInPanel'), { retries: 3 })
);
const Header = loadable(() =>
  MiscUtils.loadableRetry(() => import('../organisms/Header/Header'), { retries: 3 })
);
const GamePage = loadable(() => MiscUtils.loadableRetry(() => import('../pages/GamePage'), { retries: 3 }));
const GameTemplate = loadable(() =>
  MiscUtils.loadableRetry(() => import('../templates/Game/GameTemplate'), { retries: 3 })
);
const CategoryPage = loadable(() => MiscUtils.loadableRetry(() => import('../pages/CategoryPage'), { retries: 3 }));
const ProfilePage = loadable(() => MiscUtils.loadableRetry(() => import('../pages/ProfilePage'), { retries: 3 }));
const HomePage = loadable(() => MiscUtils.loadableRetry(() => import('../pages/HomePage'), { retries: 3 }));
const AUTH_TOKEN = 'eagle-access-token';
const SUBSCRIPTION_TYPES = {
  AD_FREE: 'adFree',
  SUBSCRIPTION: 'subscription'
};

type Props = {
  isOpened: boolean;
  isSideMenuOpened: boolean;
  leaderboardNotificationType: LeaderboardNotificationType;
  pageType: PageTypes;
  dispatch: any;
  adFree: boolean;
  subscription: boolean;
  currentRequest: string;
  isSearchTabOpened: boolean;
  t: TFunction;
};

const AbTestGeneral: React.FC = () => {
  const variation = useABTest('abtest_General', 'A');

  console.log('abtest_General group=' + variation);
  return <React.Fragment/>;
};

class AppBodyBase extends React.Component<Props> {
  static displayName = 'AppBody';

  isDevelopersPage = () => {
    if (!MiscUtils.isServer) {
      return window.location.origin.includes('developers');
    }
  };

  state = {
    hydrate: 0,
    searchFocus: false,
    isDevelopersPage: this.isDevelopersPage()
  };

  private loaded = false;
  private adFreeCookie: string | false;
  private subscriptionCookie: string | false;

  componentDidMount() {
    // TODO: this check is duplicated in UserService.ts, in the handleLoginRedirect method
    const isLoggedIn = localStorage.getItem(AUTH_TOKEN);
    const urlHasSomeAdditionalCode = () =>
      UrlService.getQSParam(window.location.search, EmailCodes.RESET_PASSWORD_CODE) ||
      UrlService.getQSParam(window.location.search, EmailCodes.CONFIRMATION_CODE) ||
      UrlService.getQSParam(window.location.search, EmailCodes.EMAIL_CHANGE_CODE) ||
      UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.ARK_PROMO);
    const isMobileDevice = () => window.matchMedia('(max-width: 1025px)').matches;

    if (isMobileDevice() && urlHasSomeAdditionalCode() && !isLoggedIn) {
      this.setState({ hydrate: 1 });
    }

    window.addEventListener('orientationchange', this.onOrientationChange);
    this.checkSubscription();

    if (this.props.pageType === PageTypes.GiftCard) {
      const giftCardParam = UserService.getCarouselGiftCardParam();

      giftCardParam
        ? Analytics.trackEvent(Analytics.giftCard.gifterStart(SubscriptionSource.HOME_CAROUSEL))
        : Analytics.trackEvent(Analytics.giftCard.gifterStart(null)); // subscriptionSource: null
    }

    window.addEventListener('keydown', this.handleEscapeClick);

    const { dispatch, t } = this.props;
    const confirmationCodeEmailChanged = UrlService.getQSParam(
      window.location.search,
      EmailCodes.EMAIL_CHANGE_CODE
    );

    confirmationCodeEmailChanged &&
    UserService.emailChangeConfirm({ code: confirmationCodeEmailChanged }).then((res) => {
      let { resCode, errorText } = res;
      const isSuccess = resCode === 200;

      errorText = errorText || 'Error occurred';
      isSuccess && isLoggedIn && UserService.userLogout();
      batch(() => {
        if (isSuccess) {
          dispatch(setSideMenuOpened(true));
          dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
        }

        dispatch(
          setSnackbarData({
            isOpened: true,
            message: isSuccess ? t('REGISTER_CONFIRM_MAIL_MESSAGE') : errorText,
            type: isSuccess ? 'success' : 'error'
          })
        );
      });
    });
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.isSearchTabOpened !== this.props.isSearchTabOpened) {
      this.setState({ searchFocus: this.props.isSearchTabOpened });
    }
  }

  handleEscapeClick = ({ key }: KeyboardEvent) => {
    const { dispatch, pageType } = this.props;

    if (key !== 'Escape') {
      return;
    }

    if (pageType === PageTypes.Game) {
      lsDispatchGamePurchaseRequestFail();
    }

    if (window.fcWidget) {
      window.fcWidget.close();
    }

    batch(() => {
      dispatch(setSideMenuOpened(false));
      dispatch(
        setProfilePanelState({
          isOpened: false,
          caption: '',
          target: ProfileRightSideRoutes.NOOP
        })
      );
    });

    document.querySelectorAll('[data="wp-close-esc"]').forEach((el) => {
      (el as HTMLElement).click();
    });
  };


  checkSubscription() {
    this.adFreeCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER);
    this.subscriptionCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION);

    this.adFreeCookie === 'true' &&
    this.props.dispatch(setPreLoadData({ adFree: adFreeService.adFreeTurnOffFilter() }));
    this.subscriptionCookie === 'true' && this.props.dispatch(setPreLoadData({ subscription: true }));

    if (localStorage.getItem(AUTH_TOKEN) || this.adFreeCookie === 'true' || this.subscriptionCookie === 'true') {
      PaymentService.getSubscriptions()
        .then((res) => {
          this.props.dispatch(setActiveUserSubscriptions(res));
          //HERE CHECK SUBSCRIPTION OR AD FREE

          const isUserSubscriber = UserService.isUserSubscriber();
          const isUserBonusOnly = UserService.isUserHasBonusOnly();

          if (isUserBonusOnly) {
            this.setSubscriptionByType(
              SUBSCRIPTION_TYPES.AD_FREE,
              adFreeService.adFreeTurnOffFilter()
            );
          }

          if (isUserSubscriber) {
            if (!MiscUtils.isServer) {
              (window as any).paid_user = true;
            }

            this.setSubscriptionByType(SUBSCRIPTION_TYPES.SUBSCRIPTION, isUserSubscriber);
            CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID, UserService.getSubscriptionId());
          } else {
            this.removeCookieSubscription();
          }
        })
        .catch((err) => {
          console.error(`Error in PaymentService getSubscriptions - ${err}`);
          AppInsightService.trackAppError(err, {
            data: 'checkSubscription()',
            errorCode: err.body.errorCode
          });
          this.setSubscriptionByType(SUBSCRIPTION_TYPES.SUBSCRIPTION, false);
          this.setSubscriptionByType(SUBSCRIPTION_TYPES.AD_FREE, adFreeService.adFreeTurnOffFilter());
          this.removeCookieSubscription();
        });
    } else {
      DisplayAdService.startVignette();
      this.removeCookieSubscription();
    }
  }

  removeCookieSubscription() {
    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID, '');
    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, false);
  }

  setSubscriptionByType(type: string, value: boolean) {
    const LS_COOKIE_KEY = type === SUBSCRIPTION_TYPES.SUBSCRIPTION ? 'SUBSCRIPTION' : 'AD_FREE_VER';

    this.props.dispatch(setPreLoadData({ [type]: value }));

    CookieService.setArkCookie(LS_COOKIE_CONSTS[LS_COOKIE_KEY], value);

    if (type === SUBSCRIPTION_TYPES.AD_FREE) {
      this.adFreeCookie === 'true';
    }

    if (type === SUBSCRIPTION_TYPES.SUBSCRIPTION) {
      this.subscriptionCookie === 'true';
    }

    if (value) {
      AdFreeService.vignetteAdsFix();
    } else {
      DisplayAdService.startVignette();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.onOrientationChange);
    window.removeEventListener('keydown', this.handleEscapeClick);
  }

  onOrientationChange = () => {
    // handle rotate ipads opened menu case
    if (this.props.isOpened || this.props.isSideMenuOpened) {
      if (window.matchMedia('(max-width: 1025px)').matches) {
        if (this.props.isSideMenuOpened) {
          UserService.closePanel();
        }
      } else if (this.props.isOpened) {
        UserService.closePanel();
      }
    }
  };

  // TODO: need to think about how to correctly use the splitting code
  loadComponents = () => {
    if (!this.loaded) {
      CategoryPage.preload();
      ProfilePage.preload();
      HomePage.preload();
      GamePage.preload();
      GameTemplate.preload();
      this.loaded = true;
    }
  };

  inputSearchTimer: any = -1;

  onChangeHandler(value: string) {
    clearTimeout(this.inputSearchTimer);
    this.inputSearchTimer = setTimeout(() => {
      if (value && value.length >= 2 && value.length <= 50) {
        void Analytics.trackEvent(Analytics.general.searchBoxQuery('search', value));
      }
    }, 2000);
  }

  searchInputOnChange = (value: string): void => {
    this.onChangeHandler(value);
  };

  getRoutes() {
    if (this.state.isDevelopersPage) {
      return langingRoutes;
    }

    return routes;
  }

  render() {
    const routes = this.getRoutes();

    return (
      <div key={this.state.hydrate}>
        <AbTestGeneral/>
        <RightSlideInPanelBase/>
        <HeaderSideMenu/>
        <FavoritesHandlerFuncComponent/>
        <div
          className={classNames(styles.body, {
            [styles.isOpened]: this.props.isOpened,
            [(styles.headerNotFixed, styles.gamePage)]: this.props.pageType === PageTypes.Game
          })}
          // TODO: it's a bad idea to hang a function on these events
          onMouseOver={() => {
            this.loadComponents();
          }}
          onFocus={() => {
            this.loadComponents();
          }}
        >
          <Header pageType={this.props.pageType}/>
          {/* TODO: refactor this search input/remove to a separate component or smth */}
          {/* search input on mobiles */}
          <div
            className={classNames(styles.mobileMenu, {
              [styles.fixedPosition]: this.props.isSideMenuOpened,
              [styles.visible]:
              (this.props.pageType === PageTypes.Home && !this.props.isSideMenuOpened) ||
              (this.props.pageType === PageTypes.Home && this.props.isSearchTabOpened),
              [styles.headerVisible]:
              this.props.pageType === PageTypes.Home && this.props.isSearchTabOpened
            })}
          >
            <TextField
              value={this.props.currentRequest}
              onFocus={() => {
                if (!this.props.isSideMenuOpened) {
                  this.props.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SEARCH_TAB));
                  this.props.dispatch(setSideMenuOpened(true));
                }
              }}
              onChange={(v) => {
                this.props.dispatch(setSideMenuRequest(v));
                this.searchInputOnChange(v);
              }}
              inputType={TextFieldTypes.SEARCH_INPUT}
              placeholder={this.props.t('SEARCH_GAME_AND_CATEGORIES_PLACEHOLDER')}
              className={styles.headerSearch}
              dataElementDescription="mobile-search-input"
              engageFocus={this.state.searchFocus}
            />
          </div>
          <PWAService.serviceWorkerRegisterScript/>
          <div className="bodyWrapper">{renderRoutes(routes)}</div>

          <Footer/>

          <Media lessThan="ARK_SMALL_DESKTOP">
            <MobileNavMenuSplitter leaderboardNotificationType={this.props.leaderboardNotificationType}/>
          </Media>

          <StructuredDataOrganization id="https://www.arkadium.com/#organization"/>
          <TagManager/>
        </div>
      </div>
    );
  }
}

export const AppBody = connect((state) => ({
  isOpened: state.profilePanel.isOpened,
  isSideMenuOpened: state.sideMenu.isOpened,
  leaderboardNotificationType: state.leaderboardNotificationType,
  pageType: state.pageType,
  adFree: state.preLoadData.adFree,
  subscription: state.preLoadData.subscription,
  currentRequest: state.sideMenu.currentRequest,
  isSearchTabOpened: state.sideMenu.isOpened && activePageSelector(state) === HeaderSideMenuTabs.SEARCH_TAB
}))(withTranslation()(AppBodyBase));
